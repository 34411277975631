'use client';

import PadiCertCardCatalog, { PadiCertCardCatalogProps } from 'components/padi-certification-cards/cert-card-catalog';
import { TasticProps } from 'frontastic/tastics/types';

const PadiCertCardCatalogTastic = ({ data, searchParams, projectConfig }: TasticProps<PadiCertCardCatalogProps>) => {
  return (
    <PadiCertCardCatalog
      searchParams={searchParams}
      projectConfig={projectConfig}
      signInAlert={data.signInAlert}
      noPhotoAlert={data.noPhotoAlert}
      photoInfoMessage={data.photoInfoMessage}
      purchaseAsGiftMessage={data.purchaseAsGiftMessage}
      defaultGalleryImages={data.defaultGalleryImages}
    />
  );
};

export default PadiCertCardCatalogTastic;
