import Modal from 'components/commercetools-ui/atoms/modal';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import Button from 'components/commercetools-ui/atoms/button';
import { useAccount } from 'frontastic';
import { getUserData, getUserIdToken } from 'helpers/padi/cognito';
import { useFormat } from 'helpers/hooks/useFormat';

export interface AvatarUploadProps {
  modalIsOpen: boolean;
  syncModalIsOpen?: any;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({ modalIsOpen, syncModalIsOpen }) => {
  const { loggedIn, getProjectConfig } = useAccount();

  const [idToken, setIdToken] = useState('');
  const [affiliateId, setAffiliateId] = useState('');
  const [userData, setUserData] = useState<{ [key: string]: any } | undefined>(undefined);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });

  const closeModal = useCallback(() => {
    syncModalIsOpen(false);
  }, []);

  useEffect(() => {
    const getIdToken = getUserIdToken();
    if (getIdToken) {
      setIdToken(getIdToken);
    }
    const cognitoUserData = getUserData();
    if (cognitoUserData) {
      setUserData(cognitoUserData);
      setAffiliateId(cognitoUserData['custom:affiliate_id']);
    }
  }, [modalIsOpen]);

  // Set users avatar.
  const saveUsersAvatar = useCallback(
    async (idToken: string, data: any) => {
      if (!idToken) return;
      setIsUploading(true);
      if (data) {
        const formData = new FormData(data);
        const proApiDomain = await getProjectConfig('EXTENSION_PADI_APIS_PRO_API_DOMAIN');
        if (proApiDomain.isError) return;
        await fetch(`${proApiDomain.setting}/certifications/photos/upload`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          body: formData,
          method: 'post',
        }).then((response) => {
          setIsUploading(false);
          closeModal();
        });
      }
    },
    [idToken, affiliateId, uploadedFile],
  );

  const handleUploadImage = async () => {
    inputRef.current?.click();
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target.files) {
      setUploadedFileUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSaveImage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploadedFile(e.target);
    saveUsersAvatar(idToken, e.target);
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        closeButton={true}
        onRequestClose={closeModal}
        style={{ content: { background: 'white', border: 'none' } }}
        closeTimeoutMS={200}
      >
        <div className="mx-auto p-24 md:ml-24 lg:ml-0">
          <div className="h-full">
            <div className="mb-10 text-2xl font-bold">
              {formatMessagePadi({ id: 'your.profile.photo', defaultMessage: 'Your Profile Photo' })}
            </div>
            <div className="mb-30 text-base">
              {formatMessagePadi({
                id: 'upload.certification.photo',
                defaultMessage: 'Upload certification photo',
              })}
            </div>
            <div className="flex flex-col gap-16 md:flex-row">
              <div className="aspect-square w-full grow rounded-md border border-gray-300 md:w-1/2">
                {uploadedFileUrl ? (
                  <div className="flex h-full content-center justify-center md:my-0">
                    <img className="h-full w-full rounded-full object-cover" src={uploadedFileUrl} />
                  </div>
                ) : (
                  <div className="flex h-full content-center justify-center md:my-0">
                    <button type="button" className="text-base font-bold text-padi-blue" onClick={handleUploadImage}>
                      {formatMessagePadi({ id: 'select.file', defaultMessage: 'Select file' })}
                    </button>
                  </div>
                )}
              </div>
              <div className="w-full grow md:w-1/2 md:pl-10">
                <div className="font-bold">
                  {formatMessagePadi({ id: 'photo.requirements', defaultMessage: 'Photo Requirements' })}
                </div>
                <div className="pl-20">
                  <ul className="list-disc">
                    <li className="py-5">
                      {formatMessagePadi({ id: 'full.face.image.you', defaultMessage: 'Full face image of you' })}
                    </li>
                    <li className="py-5">
                      {formatMessagePadi({
                        id: 'face.is.clear.not.obstructed',
                        defaultMessage: 'Face is clear, not obstructed',
                      })}
                    </li>
                    <li className="py-5">
                      {formatMessagePadi({ id: 'no.dark.glasses', defaultMessage: 'No dark glasses' })}
                    </li>
                    <li className="py-5">
                      {formatMessagePadi({ id: 'no.scuba.masks', defaultMessage: 'No scuba masks' })}
                    </li>
                    <li className="py-5">{formatMessagePadi({ id: 'no.hats', defaultMessage: 'No hats' })}</li>
                    <li className="py-5">
                      {formatMessagePadi({ id: '4.5.mb.or.smaller', defaultMessage: '4.5 MB (or smaller)' })}
                    </li>
                    <li className="py-5">
                      {formatMessagePadi({ id: 'jpg.or.png.format', defaultMessage: 'JPG or PNG format' })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-20 w-full">
              <form id="upload-avatar-form" className="flex w-full gap-16" onSubmit={handleSaveImage}>
                <input
                  className="hidden"
                  type="file"
                  id="image-file"
                  name="imageFile"
                  accept="image/png, image/jpeg"
                  onChange={handleInputChange}
                  ref={inputRef}
                ></input>
                <Button
                  className="flex w-full grow justify-center md:w-1/2"
                  type="button"
                  icon={<ArrowUpTrayIcon className="w-24 text-secondary-black" />}
                  variant="secondary"
                  onClick={handleUploadImage}
                >
                  {formatMessagePadi({ id: 'select.file', defaultMessage: 'Select file' })}
                </Button>
                <Button type="submit" className="w-full grow md:w-1/2" loading={isUploading}>
                  {formatMessagePadi({ id: 'update', defaultMessage: 'Update' })}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AvatarUpload;
